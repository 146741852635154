<template>
    
    <client-only>
        
        <!-- Toast Element -->
       <div
          ref="popupNotification"
          class="ms-2 toast position-fixed top-0 start-0 my-2"
          data-bs-delay="150000"
          style="z-index: 1080"
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
          >
          <div class="toast-body toast-dark d-flex ">
             <a @click="hideToast" type="button" aria-label="Close" class="top-right-button">
                <svg class="svg-icon" style="width: 1em; height: 1em; vertical-align: middle; fill: #ffffff; overflow: hidden;" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                   <path d="M810.66 170.66c18.33 0 30.49 12.17 30.49 30.49 0 18-12.33 30.33-30.33 30.33L572.66 500.5l238.01 238.01c12.33 12.33 12.33 30.33 0 42.66-12.33 12.33-30.33 12.33-42.66 0L500 543.16 261.99 781.17c-12.33 12.33-30.33 12.33-42.66 0-12.33-12.33-12.33-30.33 0-42.66L457.34 500.5 219.33 262.5c-12.33-12.33-12.33-30.33 0-42.66s30.33-12.33 42.66 0L500 457.84 738.01 219.83c12.33-12.33 30.33-12.33 42.66 0z"/>
                </svg>
            </a>
            <div>
                <img src="/light-bulb.gif" style="max-height:60px" class="rounded-circle me-3" />
            </div>
             
            <div>
                
                <div v-if="customerIsFound">
                 
                    <div v-if="singleContact" class="row">
                    
                        <div class="col-8" style="min-width:330px"> 
                            <h4 class="text-white"> {{customer.companyName}} </h4>
                            <h5 v-if="customer.type=='individual'"  class="text-success"> Individual Customer </h5>

                            <h5 v-else class="text-success"> {{customer.firstName}} {{customer.lastName}} </h5>
                            <h6 class="text-success"> {{$toNationalFormat(phoneNumber)}} </h6>
                        </div>
                        <div class="col-4" style="min-width: 50px; display: flex; justify-content: flex-end;">
                            
                            <a href="javascript:;" class="btn btn-success" @click="openCustomerCard()">OPEN CLIENT CARD</a>
                            </div>
                    </div>

                   <div class="text-start" v-else>
                    <!-- {{customers}} -->
                      <h4 class="text-success">Multiple Contacts Found</h4>
                      <h5 class="text-success "> {{$toNationalFormat(phoneNumber)}} </h5>
                      <table  style="border-spacing: 0 3px; border-radius:13px; margin: -3px; width: 100%; border-collapse: separate;">
                         <tbody>
                            <tr v-for="item in customers" style=" border-radius: 10px; cursor:pointer" @click=openCustomerCard(item.account_id) >
                               <td style="min-width:240px; padding: 9px;background-color:#62607f; font-size:16px" >{{item.companyName}}</td>
                               <td style="min-width:240px; background-color:#62607f; font-size:16px" v-if="item.type=='individual'"> Individual Customer</td>
                               <td v-else style="min-width:240px; background-color:#62607f; font-size:16px">{{item.firstName}} {{item.lastName}}</td>
                            </tr>
                         </tbody>
                      </table>
                   </div>
                </div>
                <div v-else>
                   <div class="row">
                      <div class="col-9" style="min-width:300px">
                         <h4 class="text-white">Contact is not found</h4>
                         <h5 class="text-success"> {{$toNationalFormat(phoneNumber)}} </h5>
                      </div>
                      <div class="col-3 text-end" >
                         <a href="javascript:;" class="btn btn-success" @click="createContact(phoneNumber)">CREATE CONTACT</a>
                      </div>
                     </div>
                    <!-- <h4 class="text-success">Contact is not found in </h4>
                   <a href="javascript:;" class="btn btn-success" @click="openCustomerCard()">CREATE CONTACT</a> -->
                   <!-- <h4 class="text-success">Multiple Contacts Found</h4> -->
                   <!-- My client card is ready for you to view. Please click the button below to view the client card. -->
                </div>
                <!-- My client card is ready for you to view. Please click the button below to view the client card. -->
            </div>
       
          </div>
       </div>

       <headerNewCRMContact/>
       
       <div>
          <div class="header-container">
             <header class="header navbar navbar-expand" style="margin-left:1px;margin-right:1px">
                <ul class="navbar-item theme-brand flex-row text-center mt-3 ">
                   <!-- <router-link to="/"> -->
                   <a href="javascript:void(0)" @click="moveToHomePage">
                   <img src="~/assets/images/logo.png" class="navbar-logo d-md-*" alt="logo" style="height:50px;margin-top:0px;" />
                   </a>
                   <!-- </router-link> -->
                </ul>

                
                <!-- <button type="button" class="btn btn-info" @click="emmulateCall">Click Callback</button> -->
                <!-- <ul class="navbar-item theme-brand flex-row text-center mt-3 d-block d-md-none ">
                   <router-link to="/">
                       <img src="~/assets/images/logo-mob.png" class="navbar-logo d-sm-block" alt="logo" style="height:50px;margin-top:0px;" />
                   </router-link>
                   </ul> -->
                   
                <div class="navbar-item flex-row ms-auto" >
                   <div class="dropdown nav-item notification-dropdown btn-group ">
                    <div class="me-3">
                        <headerVmAlert/>  
                    </div>
                    <div v-if="user.is_authorized" class="text-primary d-none d-md-block mt-2" style="font-size:13px;  text-transform: uppercase;">
                      <!-- {{company}}  -->
                      
                        <strong>{{ company.name }}</strong>
                      </div>
                   </div>

                   
                   <div class="dropdown nav-item user-profile-dropdown notification-dropdown btn-group" style="z-index:1001">
                      <a v-if="user.is_authorized" href="javascript:;" id="ddluser" data-bs-toggle="dropdown"
                         aria-expanded="false">
                         <client-only>
                            <template v-if="'avatar' in user">
                               <div class="avatar avatar-sm avatar-secondary mt-1 me-0">
                                  <img class="avatar-title rounded " :src="user.avatar" alt="avatar" />
                               </div>
                            </template>
                            <template v-else>
                               <div class="avatar avatar-sm avatar-secondary me-0" :class="color">
                                  <span class="avatar-title rounded-circle">{{ user.initials }}</span>
                               </div>
                            </template>
                         </client-only>
                      </a>
                      
                      <ul class="dropdown-menu dropdown-menu-right navbar-nav" aria-labelledby="ddluser">
                         <li role="presentation" class="text-center">
                            <client-only>
                               <router-link to="/profile" class="dropdown-item">
                                  <h5>{{ user.firstname }} {{ user.lastname }}</h5>
                                  <h6>{{ user.email }}</h6>
                               </router-link>
                            </client-only>
                         </li>
                         <hr role="separator" aria-orientation="horizontal" size="0" class="dropdown-divider" />
                         <li role="presentation" class="text-end">
                            <div class="me-3 mt-2 mb-2">
                                <a class="font-family-credit" style="cursor:pointer;color:#0045c4" @click="navigateTo('/create')"> + Create a new client account</a>
                            </div>
                         </li>
                         <li role="presentation" class="text-end">
                            <button class="btn btn-sm mt-2 mb-2 me-3" @click="signOut()"> SIGN OUT </button>
                         </li>
                      </ul>
                      
                      <hr>
                      
                   </div>
                </div>
             </header>
          </div>
          <!-- {{company}} -->
       </div>
    </client-only>
    <!-- {{messages}} -->
    <!-- {{company}} -->
 </template>

<script setup>
// import { useUserWebSocket } from '~/composables/useUserWebSocket';
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;
const { $swal, $socket, $get, $parseJwt} = useNuxtApp();
const router = useRouter();
const contactNotification = useNewContactNotification();

const showPhoneWindow = useShowPhoneWindow(); 


import './assets/sass/elements/avatar.scss';
import '~/assets/sass/widgets/widgets.scss';

// Reference for the Toast DOM element
const popupNotification = ref(null);

// Reference for the Bootstrap Toast instance
let popupNotificationInstance = null;

// Initialize the Toast instance
const initToast = async () => {
  // Ensure the DOM is rendered
  await nextTick();

  if (popupNotification.value) {
    popupNotificationInstance = new window.bootstrap.Toast(popupNotification.value);

    // Add an event listener to reset toast state when hidden
    popupNotification.value.addEventListener('hidden.bs.toast', () => {
      console.log("Toast hidden. Ready to show again.");
    });

    console.log("Toast instance initialized:", popupNotificationInstance);
  } else {
    console.error("Toast element not found!");
  }
};
// Function to show the Toast
const showToast = () => {
  if (popupNotificationInstance) {
    popupNotificationInstance.show();
  } else {
    console.error("Toast instance is not initialized.");
  }
};


const emmulateCall = () => {
    //let ci = Math.floor(Math.random() * 9000000000) + 10000000000; // Ensure it's 10 digits
    let ci = "18184349148";
    let account_id = sessionStorage.getItem('account_id');
    // alert(account_id);
    userMessages.value = JSON.stringify({"app":"call-state","data":{"account_id":account_id, "callcenter_id":"678b91515718cf68c7db5fcc", "direction":"inbound","type":"inbound-acd","uuid":"e7b6ab90-63e6-4cbc-9bd1-2dbc4b5de13c","state":2,"node":"164.92.67.48:9098","sip_account_id":"6730099a607b95680497d0ce","callerid":`+${ci}`,"display_name":"U2FsZXM="}});
}

const openCustomerCard = (account_id) => {
    if (account_id == undefined){
        account_id = callingAccoutId.value;
    }
    showPhoneWindow.value = false;
    let link  = "/company/customers/"+account_id;
    navigateTo(link);
    hideToast();
}

const createContact = (number) => {
    // alert(number)
    contactNotification.value = {phone: number, callcenter_id: callcenterId.value};
    // let link  = `/crm/contacts`;
    // navigateTo(link);
    hideToast();
}


const hideToast = () => {
    userMessages.value = '{}'
  if (popupNotificationInstance) {
    popupNotificationInstance.hide();
  } else {
    console.error("Toast instance is not initialized.");
  }
};

const CRMContactsSettings = useCRMContactSettings();
const CRMAccountSettings = useCRMAccountSettings();
const CRMIssueTrackerSettings = useCRMIssueTrackerSettings();

// Initialize the Toast after the DOM is mounted
onMounted(async () => {
  initToast();
  const crmSettings = await $get("/api/protected/crm/settings");
//   alert(JSON.stringify(crmSettings.data.contactSettings));
  CRMContactsSettings.value = crmSettings.data.contactSettings;
  CRMAccountSettings.value = crmSettings.data.accountSettings;
  CRMIssueTrackerSettings.value = crmSettings.data.issueTrackerSettings;
  
    const account_id = sessionStorage.getItem('account_id');
    if (account_id != null){
        const token = sessionStorage.getItem('token')
        const decodedToken = $parseJwt(token);
        for (let member of decodedToken.membership){
            if (member.account_id == account_id){
                company.value.name = member.name;
                company.value.country = member.country;
                company.value.role = member.role;
                company.value.mode = member.mode;
                company.value.account_user_id = member.account_user_id;
            }
        }

    
    }
//   if (decodedToken.membership.length == 1){
//             for (let member of decodedToken.membership){
//                 if (member.account_id == account_id){
//                     company.value.name = member.name;
//                     company.value.country = member.country;
//                     company.value.role = member.role;
//                     company.value.mode = member.mode;
//                     company.value.account_user_id = member.account_user_id;
//                 }
//             }
//             // company.value.name = decodedToken.membership[0].name;
//             // company.value.country = decodedToken.membership[0].country;
//             // company.value.role = decodedToken.membership[0].role;
//             // company.value.mode = decodedToken.membership[0].mode;
//             // company.value.account_user_id = decodedToken.membership[0].account_user_id;
//         }
  
});

// crm notification values
const phoneNumber = ref(""); 
const callcenterId = ref(""); 
const cardLink = ref("");
const callingAccoutId = ref("");
const customerIsFound = ref(false);
const singleContact = ref(false);

const customer = ref({});
const customers = ref([]);

import { useUserWebSocket } from '~/composables/useUserWebSocket';

const { userMessages, initUserWebSocket } = useUserWebSocket();

const initializeUserWebSocket = () => {
    const token = sessionStorage.getItem('token');
    const account_id = sessionStorage.getItem('account_id');
    const account_user_id = sessionStorage.getItem('account_user_id');

    if (token && account_id && account_user_id) {
        initUserWebSocket(token, account_id, account_user_id);
    } else {
        console.log('Missing token, account_id, or account_user_id for user subscription');
    }
};

initializeUserWebSocket();
const showMessage = (msg = '', type = 'success') => {
        const toast = $swal.mixin({ toast: true, position: 'top', showConfirmButton: true, timer: 15000 });
        toast.fire({ icon: type, title: msg, padding: '10px 20px' });
    };

watch(() => userMessages.value,  async (newValue) => {
    
    try {
        let message = JSON.parse(userMessages.value);
        switch (message.app){
            case "logout":
                await signOut(); 
            break;
            case "refresh-key":
                let {token} = await $get("/api/protected/refresh-key"); 
                sessionStorage.setItem("token", token);

            break;
            case "voicemail":
            
            break;
            case "missed-call":
            showMessage("You have a missed call", "warning")
                // $swal.fire({
                //     position: "top-start",
                //     icon: "warning",
                //     title: "You have a missed call.",
                //     text: "Remeber to call back to the customer!",
                //     showConfirmButton: true,
                //     // timer: 3000
                // });
                // alert("You have a missed call!!!");
                // let {token} = await $get("/api/protected/refresh-key"); 
                // sessionStorage.setItem("token", token);
            break;
            case "call-state":
                console.log("Call state changed ************:", message);
            if (message.data.state == 5){
                hideToast();
            }

            if (message.data.state == 2){
                // Trying to find the contact by phone
                if (message.data.direction == 'outbound'){
                    phoneNumber.value = `+${message.data.destination}`;
                    callcenterId.value = message.data.callcenter_id;
                } 

                if (message.data.direction == 'inbound'){
                    phoneNumber.value = message.data.callerid;
                    callcenterId.value = message.data.callcenter_id;
                }

                let contact = await $get("/api/protected/crm/contact/"+ encodeURIComponent(phoneNumber.value)+"?callcenter_id="+message.data.callcenter_id);
                // + "?callcenter_id="+message.data.callcenter_id;
                if (contact.data.length > 0){
                    customerIsFound.value = true;
                    if (contact.data.length == 1){
                        singleContact.value = true;
                        customer.value = contact.data[0];
                        callingAccoutId.value = contact.data[0].account_id;
                        
                        // cardLink.value = "/crm/contacts/"+contact.data[0].account_id;
                    } else {
                        singleContact.value = false;
                        customers.value = contact.data;
                    }
                    // singleContact.value = true;
                    // customer.value = contact.data[0];
                    // customerIsFound.value = true;
                } else {
                    customerIsFound.value = false;
                }
                // customerIsFound
                // initToast();
                if (message.data.type != "p2p"){
                    showToast();
                }
                
            }        
                    // alert("Call state changed", userMessages.value.call_state);
                //     $swal.fire({
                //         position: "top-start",
                //             icon: "warning",
                //             title: "You have a missed call.",
                //             text: "Remeber to call back to the customer!",
                //             showConfirmButton: true,
                //     // timer: 3000
                // });
                // $swal.fire({
                //     title: "Custom width, padding, color, background.",
                //     width: 600,
                //     padding: "3em",
                //     color: "#716add",
                //     background: "#fff url(/images/trees.png)",
                //     backdrop: `
                //         rgba(0,0,123,0.4)
                //         url("/images/nyan-cat.gif")
                //         left top
                //         no-repeat
                //     `
                // });
            
            break;

            
        }
        
    } catch (error){
        console.log(error)
    }

});

const moveToHomePage = () => {
    const token = sessionStorage.getItem('token');
    const decodedToken = $parseJwt(token);
    
    if (decodedToken.membership.length == 1){
        router.push("/company");
    }
    if (decodedToken.membership.length > 1){
        sessionStorage.removeItem('account_id');
        company.value.name = "";
        router.push("/");
    }
}

// const { messages: userMessages, init: initUserWebSocket } = useUserWebSocket();


// const initializeUserWebSocket = () => {
    
//   const token = sessionStorage.getItem('token');
//   const account_id = sessionStorage.getItem('account_id');
//   const account_user_id = sessionStorage.getItem('account_user_id');
  
//   if (token && account_id && account_user_id) {
//     initUserWebSocket(token, account_id, account_user_id);
    

//   } else {
//     console.log('Missing token, account_id, or account_user_id for user subscription');
//   }
// };
// initializeUserWebSocket();

// import { loadStripe } from '@stripe/stripe-js' 

// const backendUrl = '/api/protected/stripe/session'
// const loading = ref(false)

  

// const { $subscribe } = useNuxtApp(); // Notification web scoket
// if (process.client){
//     $subscribe(sessionStorage.getItem("account_id"));
//     console.log("Subscribed to events: ", sessionStorage.getItem("account_id"))
// }

const user = useUser();
user.value.is_authorized = true;
const dataIsChanged = useDataChanged();

// const modalTitle = ref("");
// let PaymentModal = ref(null);
// let phoneSelector = ref(null);
// let payment_type = ref("included_did");
// const nextButtonCaption = ref("NEXT: Payment details");
// const paymentParams = ref({});

const screen = ref("number_selection");


const addAdditinalPhoneNumber = () => {
    const screen = ref("number_selection");
    paymentParams.value = {paymentSettings:{subscription:5,service:"additional_did"}};
    modalTitle.value = "Purchase additional Phone Number";
    
   
    
    payment_type.value="additional_did";
    PaymentModal.value.openModal(); 
    phoneSelector.value.getRandomNumber();
    
}


// Function to subscribe to user events
// function subscribeUser() {
//   const token = sessionStorage.getItem('token'); // Ensure token is stored in sessionStorage
//   const account_id = sessionStorage.getItem('account_id');
//   const account_user_id = sessionStorage.getItem('account_user_id');

//   if (token && account_id && account_user_id) {
//     $socket.subscribeToUserEvents(token, account_id, account_user_id);
//   } else {
//     console.log('Missing token, account_id, or account_user_id for subscribing to user events');
//   }
// }

// function initializeSocket() {
//   $socket.init((error) => {
//     if (error) {
//       console.error('***************   Error initializing WebSocket:', error);
//     } else {
//       console.log(' ***************   WebSocket initialized successfully');
//       subscribeUser();
//       //   subscribeCallcenter()
//     }
//   });
// }
// subscribeUser();
// initializeSocket();

// if (process.client){
//     if ($socket) {
//         const { socket, init, subscribeToUserEvents } = $socket;
//         init((err) => {
//             if (err) {
//             console.error(err);
//             } else {
//             console.log('Initialization successful');
//             subscribeToUserEvents(sessionStorage.getItem('account_id'));
//             }
//         });
//     } else {
//         console.error('Socket plugin not found');
//     }
// }

const openStripePotal = async() => {
    let res = await $get("/api/protected/stripe/portal");
    // console.log(res.session.url);
    // navigateTo(res.session.url)
    window.location.href = res.session.url;
}

let color = ref("");

watch(() => user.value,  (newValue) => {
    if (!((newValue.firstname == undefined) && (newValue.lastname == undefined)) ) {
        user.value.initials = newValue.firstname[0]+newValue.lastname[0];
        user.value.has_avatar = false;
        color.value = getUserIdColor(newValue.email)
    }
    
}, { deep: true, immediate:true })

const company = useCompany();

let bc = useBreadcrumbs();
    // bc.value = [{ name: "Main page", is_current: true, url: "none" }];


function getUserIdColor(userId) {
    function hashCode(str) {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            const char = str.charCodeAt(i);
            hash = (hash << 5) - hash + char;
            hash |= 0; // Convert to 32bit integer
        }
        return hash;
    }
    const colors = ['avatar-primary', 'avatar-secondary', 'avatar-danger']; // List of available colors
    const colorIndex = hashCode(userId) % colors.length; // Use hash code of user ID to get an index
    
    return colors[colorIndex];
}



const signOut = async () => {
    if(process.client) {
        sessionStorage.removeItem('token')
        sessionStorage.removeItem('account_id')
        sessionStorage.removeItem('account_user_id')
        sessionStorage.removeItem('callcenter_id')
        sessionStorage.removeItem('callcenter_name')
        sessionStorage.removeItem('country')
        sessionStorage.removeItem('role')
    }
        var values = [],
            keys = Object.keys(localStorage),
            i = keys.length;
            while ( i-- ) {
                // values.push( localStorage.getItem(keys[i]) );
                localStorage.removeItem(keys[i])
            }

            console.log(values);

    reloadNuxtApp({
        path: "/",
        ttl: 1000, // default 10000
    });
    // await navigateTo("/login")
}

// const upgrade = () => {
//     modalTitle.value = "Upgrade account to Production";
//     paymentParams.value = {paymentSettings:{allowed:true, subscription:20, topup: 30, type:"included_did"}};
//     PaymentModal.value.openModal();
//     payment_type.value="included_did";
//     phoneSelector.value.getRandomNumber();
// }

// const next = async () => {


    
    
//     // alert(screen.value)
//     switch (screen.value){
//         case "number_selection":
//             screen.value = "invoice";
//             nextButtonCaption.value = "NEXT: Checkout"
//         break;

//         case "invoice":
//             // nextButtonCaption.value = "NEXT: Checkout"
//             sessionStorage.setItem("selectedDID", paymentParams.value.SelectedNumber.phoneNumber);
//             sessionStorage.setItem("topupAllowed", paymentParams.value.paymentSettings.allowed);
//             sessionStorage.setItem("topupAmount", paymentParams.value.paymentSettings.topup);
//             startCheckout();
//         break;

        
//     //     case "credit_card":
//     //         let token = await creitCard.value.handleSubmit();
//     //         console.log(token)
//     //     break;
//     }
//     // if (screen.value == "invoice"){

//     // }
//     // alert("Yes");
// }

// const startCheckout = async () => {
//     try {
//       loading.value = true
//       let url = backendUrl;
//       let topup_allowed = paymentParams.value.paymentSettings.allowed;
//       if (topup_allowed ){
//         url+= "?topup=true&amount="+paymentParams.value.paymentSettings.topup+"&service="+paymentParams.value.paymentSettings.service;
//       } else {
//         url+= "?topup=false&service="+paymentParams.value.paymentSettings.service;
//       }
//       // Fetch the checkout session from the backend
//       const response = await fetch(url, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': sessionStorage.getItem("token")
//         }
//       })
  
//       const { sessionId } = await response.json()
  
//       // Load Stripe.js with your publishable key
//       const stripe = await loadStripe('pk_test_51NIPLiIHvM6IbKomtCvHiQcP1TunrBOsSLrCovQGd9jSLRZsQt4ebxKduGByjWZ9Ga2xFJqOD2UrXKsZtVY1yj7q000DRYg1cK')
  
//       // Redirect to the Stripe Checkout page using the sessionId
//       const { error } = await stripe.redirectToCheckout({ sessionId })
  
//       if (error) {
//         console.error('Stripe Checkout error:', error)
//         loading.value = false
//       }
//     } catch (error) {
//       console.error('Checkout error:', error)
//       loading.value = false
//     }
//   }


</script>

<style>
.header-container {
    z-index: 1030;
    border-bottom: 1px solid #034ecc;
}

    #my_blue_nav li>a:hover {
        background-color: blue;
    }

    #my_blue_nav li.active a {
        background-color: blue !important;
    }

    #privacyWrapper .privacyContent {
        margin-top: 0px;
    }

    .navbar .theme-brand li.theme-logo img {

        height: 50px;
    }

    .navbar {
        background: #ffffff;
    }
    .toast {
        --bs-toast-zindex: 1090;
        --bs-toast-padding-x: 0.75rem;
        --bs-toast-padding-y: 0.5rem;
        --bs-toast-spacing: 1.5rem;
        --bs-toast-max-width: 650px;
    }
    .top-right-button {
    position: absolute; /* Allows positioning relative to the container */
    top: 10px; /* Distance from the top of the container */
    right: 10px; /* Distance from the right of the container */
  }
  .btn-close {
    --bs-btn-close-color: #ffffff;
  }
</style>

<style scoped>
.navbar .navbar-item .nav-item.dropdown.notification-dropdown .nav-link svg {
    color: rgb(37, 97, 208);
    width: 22px;
    height: 22px;
}

.navbar .navbar-item .nav-item.dropdown.notification-dropdown .dropdown-menu .media.server-log svg {
    color: #004d96;
    fill: rgb(0 63 150 / 37%);
}

</style>