export const useVMCount = () => useState("vmCount", () => {
    return ref(0)
})

export const useCBCount = () => useState("cbCount", () => {
    return ref(0)
})

export const useFaxCount = () => useState("faxCount", () => {
    return ref(0)
})

export const useMissedCount = () => useState("missedCount", () => {
    return ref(0)
});

